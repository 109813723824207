import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {User} from 'app/core/user/user.types';
import {map, Observable, ReplaySubject, tap} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserService {
    private _httpClient = inject(HttpClient);
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        if (!this._user.observed) {
            this.user = {
                id: '1',
                registrationId: 123,
                name: 'Anonymous',
                email: '',
                // avatar: 'assets/images/avatars/brian-hughes.jpg',
                status: 'online',
            };
        }
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current signed-in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                if (user === undefined || user === null) {
                    user = {
                        id: '1',
                        registrationId: 123,
                        name: 'Anonymous',
                        email: '',
                        // avatar: 'assets/images/avatars/brian-hughes.jpg',
                        status: 'online',
                    };
                }
                this._user.next(user);
            }),
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', {user}).pipe(
            map((response) => {
                this._user.next(response);
            }),
        );
    }
}
