import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash-es';
import {documents as documentData} from "./data";
import {DocumentContractV1} from "../../../modules/client-app/send/documentContractV1";
import {v4 as uuidv4} from 'uuid';

@Injectable({providedIn: 'root'})
export class DocumentsMockApi
{
    private _documents: any = documentData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Documents - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/postbird/documents')
            .reply(() => [200, cloneDeep(this._documents)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Documents - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/postbird/documents', undefined,2000)
            .reply(() => {
                const documentContract = new DocumentContractV1();
                documentContract.externalId = uuidv4();
                documentContract.documentName = 'test.pdf';
                this._documents.push(documentContract);

                return [200, 1]
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Attachments - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/postbird/attachments', undefined,2000)
            .reply(() => {
                // const documentContract = new DocumentContractV1();
                // documentContract.externalId = uuidv4();
                // documentContract.documentName = 'test.pdf';
                // this._documents.push(documentContract);

                return [200, uuidv4()]
            });
    }
}
