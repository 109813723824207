import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {User} from "../../../../core/user/user.types";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {Router} from "@angular/router";
import {UserService} from "../../../../core/user/user.service";

@Component({
  selector: 'app-user-authenticated',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule],
  templateUrl: './user-authenticated.component.html',
  styleUrl: './user-authenticated.component.scss'
})
export class UserAuthenticatedComponent {
  @Input() user: User;
  @Input() showAvatar: boolean = true;
  constructor(
      private _router: Router,
      private _userService: UserService,
  )
  {
  }
  /**
   * On init
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void
  {
    // Return if user is not available
    if ( !this.user )
    {
      return;
    }

    // Update the user
    this._userService.update({
      ...this.user,
      status,
    }).subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void
  {
    this._router.navigate(['/sign-out']);
  }
}
