/* eslint-disable */
import { DateTime } from 'luxon';
import {DocumentContractV1} from "../../../modules/client-app/send/documentContractV1";

/* Get the current instant */
const now = DateTime.now();

export const documents: DocumentContractV1[] = [

];
