import {Injectable} from '@angular/core';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {cloneDeep} from 'lodash-es';
import {configurations as configurationData} from "./data";

@Injectable({providedIn: 'root'})
export class ConfigurationMockApi {
    private _configurations: any = configurationData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Activities - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/postbird/configurations')
            .reply(() => [200, cloneDeep(this._configurations)]);
    }
}
