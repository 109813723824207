import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class SnackbarService {
    status: BehaviorSubject<SnackbarConfig> = new BehaviorSubject(new SnackbarConfig());
    status$: Observable<SnackbarConfig> = this.status.asObservable();

    constructor() {
    }

    showError(message: string) {
        const config:  SnackbarConfig = {
            snackbarText: message,
            isVisible: true,
            snackbarType: SnackbarType.error
        };

        this.status.next(config);
    }

    showWarning(message: string) {
        const config:  SnackbarConfig = {
            snackbarText: message,
            isVisible: true,
            snackbarType: SnackbarType.warning
        };

        this.status.next(config);
    }

    showInfo(message: string) {
        const config:  SnackbarConfig = {
            snackbarText: message,
            isVisible: true,
            snackbarType: SnackbarType.info
        };

        this.status.next(config);
    }
}

export enum SnackbarType {
    default = 0,
    error = 1,
    warning = 2,
    info = 3
}

export class SnackbarConfig {
    snackbarText: string = '';
    isVisible: boolean = false;
    snackbarType: SnackbarType;
}
