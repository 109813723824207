/* eslint-disable */
import {DateTime} from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const batches = [
    {
        id: '493190c9-5b61-4912-afe5-78c21f1044d7',
        progress: 102,
        documentCount: 1503,
        channels: [
            {
                channelType: 0,
                icon: 'circle'
            },
            {
                channelType: 1,
                icon: 'circle'
            }
        ],
        summary: [
            {
                phase: 'initial sent',
                channel: 1,
                sent: 3,
                delivered: 74,
                failed: 35,
                inProgress: 1345
            },
            {
                phase: 'follow up',
                channel: 0,
                sent: 13,
                delivered: 174,
                failed: 5,
                inProgress: 1445
            }
        ],
        startDate: now,
        status: 1,
        configuration: {
            id: '493190c9-5b61-4912-afe5-78c21f1044d7',
            name: 'Invoices',
            addendaCount: 2,
            templateCount: 1,
            qrCodeCount: 1,
            zoneCount: 0,
            documentPreparation: {
                documentPreparationType: 2,
                mergeTemplateName: 'mergeTemplate1.docx',
                mergeTemplateId: '45d41f6e-c9ed-4fa2-bd73-bf6197998170'
            },
            documentImprovements: [
                {
                    documentImprovementType: 0,
                    documentName: 'Addendum1.pdf',
                    documentId: '8044aa90-014b-4f95-89be-ca8a467102dc'
                }
            ],
            sendingConfigurations: [
                {
                    id: '74549b0d-7b23-4f92-920a-3d4be853537a',
                    icon: 'heroicons_solid:envelope-open',
                    description: 'Normal e-mail',
                    channel: 'Email',
                    conditionsText: 'All documents',
                    level: 0,
                    extraContent: `Send by email configuration`,
                    linkedContent: "Email message settings",
                    link: '/apps/mailbox',
                    useRouter: true
                },
                {
                    id: '6e3e97e5-effc-4fb7-b730-52a151f0b641',
                    icon: 'heroicons_solid:printer',
                    description: 'Fallback to paper',
                    channel: 'Paper',
                    conditionsText: 'When bounced, when zone contains "Test value"',
                    previousStepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                    level: 1,
                    conditions: [
                        {
                            conditionType: 2,
                            stepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                            stepStatus: 'Bounced'
                        },
                        {
                            conditionType: 1,
                            zoneId: '396d572f-95e6-44c1-9fdb-e3398a8c371d',
                            zoneValue: 'Test value'
                        }
                    ],
                    extraContent: `Prior - color - recto-verso`,
                    linkedContent: 'Paper settings',
                    link: '/dashboards/project',
                    useRouter: true,
                },
                {
                    id: 'b91ccb58-b06c-413b-b389-87010e03a120',
                    icon: 'heroicons_solid:chat-bubble-bottom-center-text',
                    description: 'Send Text message',
                    channel: 'Sms',
                    previousStepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                    level: 1,
                    conditionsText: 'After 2 days',
                    conditions: [
                        {
                            conditionType: 2,
                            stepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                            stepStatus: 'Bounced'
                        }
                    ],
                    extraContent: `Send by SMS configuration`,
                    linkedContent: "Text message settings",
                    link: '/apps/mailbox',
                    useRouter: true,
                },
                {
                    id: 'c0d1ff16-e813-44ee-b270-a15289235aa4',
                    icon: 'heroicons_solid:photo',
                    description: 'Send postal card',
                    channel: 'PostalCard',
                    previousStepId: 'b91ccb58-b06c-413b-b389-87010e03a120',
                    level: 0,
                    conditionsText: 'After 2 days',
                    conditions: [
                        {
                            conditionType: 3,
                            WaitingTime: 2,
                            IntervalType: 'days',
                            From: 'start'
                        }
                    ],
                    extraContent: `Send by postal card configuration`,
                    linkedContent: "Postal card settings",
                    link: '/apps/mailbox',
                    useRouter: true,
                }
            ],
        },
    },
    {
        id: '493190c9-5b61-4912-afe5-78c21f1044d7',
        progress: 102,
        documentCount: 1503,
        channels: [
            {
                channelType: 0,
                icon: 'circle'
            },
            {
                channelType: 1,
                icon: 'circle'
            }
        ],
        summary: [
            {
                phase: 'initial sent',
                channel: 1,
                sent: 3,
                delivered: 74,
                failed: 35,
                inProgress: 1345
            },
            {
                phase: 'follow up',
                channel: 0,
                sent: 13,
                delivered: 174,
                failed: 5,
                inProgress: 1445
            }
        ],
        startDate: now,
        status: 1,
        configuration: {
            name: 'Campaign letter',
            id: 'a40258fe-2f6e-41cc-875b-598530d1825c',
            addendaCount: 2,
            templateCount: 1,
            qrCodeCount: 1,
            zoneCount: 0,
            documentPreparation: {
                documentPreparationType: 2,
                mergeTemplateName: 'mergeTemplate1.docx',
                mergeTemplateId: '45d41f6e-c9ed-4fa2-bd73-bf6197998170'
            },
            documentImprovements: [
                {
                    documentImprovementType: 0,
                    documentName: 'Addendum1.pdf',
                    documentId: '8044aa90-014b-4f95-89be-ca8a467102dc'
                }
            ],
            sendingConfigurations: [
                {
                    id: '74549b0d-7b23-4f92-920a-3d4be853537a',
                    icon: 'heroicons_solid:envelope-open',
                    description: 'Normal e-mail',
                    channel: 'Email',
                    conditionsText: 'All documents',
                    level: 0,
                    extraContent: `Send by email configuration`,
                    linkedContent: "Email message settings",
                    link: '/apps/mailbox',
                    useRouter: true
                },
                {
                    id: '6e3e97e5-effc-4fb7-b730-52a151f0b641',
                    icon: 'heroicons_solid:printer',
                    description: 'Fallback to paper',
                    channel: 'Paper',
                    conditionsText: 'When bounced, when zone contains "Test value"',
                    previousStepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                    level: 1,
                    conditions: [
                        {
                            conditionType: 2,
                            stepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                            stepStatus: 'Bounced'
                        },
                        {
                            conditionType: 1,
                            zoneId: '396d572f-95e6-44c1-9fdb-e3398a8c371d',
                            zoneValue: 'Test value'
                        }
                    ],
                    extraContent: `Prior - color - recto-verso`,
                    linkedContent: 'Paper settings',
                    link: '/dashboards/project',
                    useRouter: true,
                },
                {
                    id: 'b91ccb58-b06c-413b-b389-87010e03a120',
                    icon: 'heroicons_solid:chat-bubble-bottom-center-text',
                    description: 'Send Text message',
                    channel: 'Sms',
                    previousStepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                    level: 1,
                    conditionsText: 'After 2 days',
                    conditions: [
                        {
                            conditionType: 2,
                            stepId: '74549b0d-7b23-4f92-920a-3d4be853537a',
                            stepStatus: 'Bounced'
                        }
                    ],
                    extraContent: `Send by SMS configuration`,
                    linkedContent: "Text message settings",
                    link: '/apps/mailbox',
                    useRouter: true,
                },
                {
                    id: 'c0d1ff16-e813-44ee-b270-a15289235aa4',
                    icon: 'heroicons_solid:photo',
                    description: 'Send postal card',
                    channel: 'PostalCard',
                    previousStepId: 'b91ccb58-b06c-413b-b389-87010e03a120',
                    level: 0,
                    conditionsText: 'After 2 days',
                    conditions: [
                        {
                            conditionType: 3,
                            WaitingTime: 2,
                            IntervalType: 'days',
                            From: 'start'
                        }
                    ],
                    extraContent: `Send by postal card configuration`,
                    linkedContent: "Postal card settings",
                    link: '/apps/mailbox',
                    useRouter: true,
                }
            ],
        },
    },
];
