import {Injectable} from '@angular/core';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {cloneDeep} from 'lodash-es';
import {batches as batchData} from "./data";
import {v4 as uuidv4} from 'uuid';
import {BatchV1} from "../../../modules/client-app/batches/batchV1.types";

@Injectable({providedIn: 'root'})
export class BatchMockApi {
    private _batches: any = batchData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Activities - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/postbird/batches')
            .reply(() => [200, cloneDeep(this._batches)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Activities - POST
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/postbird/batches')
            .reply(() => {
                this._batches.push(this._fuseMockApiService.payLoad);
                return [200, uuidv4()];
            });
    }
}
