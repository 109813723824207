import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FuseCardComponent} from "../../../../../@fuse/components/card";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {SnackbarConfig, SnackbarService, SnackbarType} from "../../../../mock-api/postbird/SnackbarSevice";

@Component({
  selector: 'app-snackbar-container',
  standalone: true,
    imports: [CommonModule, FuseCardComponent, MatProgressBarModule],
  templateUrl: './snackbar-container.component.html',
  styleUrl: './snackbar-container.component.scss'
})
export class SnackbarContainerComponent {
  snackbarVisible = false;
  snackbarClass = 'info';
  snackbarText = '';

  constructor(private snackbarService: SnackbarService) {
    snackbarService.status$.subscribe((snackbarConfig: SnackbarConfig) => {
      this.snackbarText = snackbarConfig.snackbarText
      this.snackbarVisible = snackbarConfig.isVisible;

      switch (snackbarConfig.snackbarType) {
        case SnackbarType.info: this.snackbarClass = 'info'; break;
        case SnackbarType.warning: this.snackbarClass = 'warning'; break;
        case SnackbarType.error: this.snackbarClass = 'error'; break;
        case SnackbarType.default: this.snackbarClass = 'default'; break;
      }

      setTimeout(() => {this.hide()}, 2000);
    });
  }

  private hide() {
    this.snackbarVisible = false;
  }
}
