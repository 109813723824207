import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-not-authenticated',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-not-authenticated.component.html',
  styleUrl: './user-not-authenticated.component.scss'
})
export class UserNotAuthenticatedComponent {

}
